var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyAccountView",
      attrs: { "data-test-id": "company_account-view" },
    },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Account ")]),
      _c(
        "ui-card",
        {
          staticClass: "AccountSettings w-100",
          attrs: { "data-test-id": "company_account-view" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between align-items-start" },
            [
              _c("div", { staticClass: "w-100" }, [
                _c("h2", { staticClass: "mb-3" }, [
                  _vm._v(" Company Profile "),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-3",
                          attrs: { label: "Company name" },
                        },
                        [
                          _c("p", { staticClass: "text-break" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.get(_vm.company, "commercialName") ||
                                    _vm.FALLBACK_MESSAGE.dash
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        { staticClass: "mb-3", attrs: { label: "Email" } },
                        [
                          _c("p", { staticClass: "text-break" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.get(_vm.company, "email") ||
                                    _vm.FALLBACK_MESSAGE.dash
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        { staticClass: "mb-3", attrs: { label: "Phone" } },
                        [
                          _c("p", { staticClass: "text-break" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.get(_vm.company, "phoneNumber") ||
                                    _vm.FALLBACK_MESSAGE.dash
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        { staticClass: "mb-4", attrs: { label: "Address" } },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "mb-1",
                              attrs: { "data-test-id": "address_city" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "addressCity") ||
                                      _vm.FALLBACK_MESSAGE.dash
                                  ) +
                                  ", " +
                                  _vm._s(
                                    _vm.get(_vm.company, "addressCountry") ||
                                      _vm.FALLBACK_MESSAGE.dash
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "emobg-color-ink-light mb-1",
                              attrs: { "data-test-id": "address_street" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "addressStreet") ||
                                      _vm.FALLBACK_MESSAGE.dash
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "addressPostalCode") ||
                                      _vm.FALLBACK_MESSAGE.dash
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-3",
                          attrs: { label: "Floor, door, apartment..." },
                        },
                        [
                          _c("p", { staticClass: "text-break" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.get(_vm.company, "addressStreet2") ||
                                    _vm.FALLBACK_MESSAGE.dash
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        { staticClass: "mb-4", attrs: { label: "Operator" } },
                        [
                          _c("p", { staticClass: "text-break" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.get(_vm.company, "csOperator.name") ||
                                    _vm.FALLBACK_MESSAGE.dash
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        { staticClass: "mb-3", attrs: { label: "Language" } },
                        [
                          _c("p", { staticClass: "text-break" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.get(_vm.company, "language.localeCode") ||
                                    _vm.FALLBACK_MESSAGE.dash
                                ) +
                                " - " +
                                _vm._s(
                                  _vm.get(_vm.company, "language.name") ||
                                    _vm.FALLBACK_MESSAGE.dash
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-3",
                          attrs: { label: "Company size" },
                        },
                        [
                          _c("p", { staticClass: "text-break" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.get(_vm.company, "size") ||
                                    _vm.FALLBACK_MESSAGE.dash
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("EditButton", {
                staticClass: "mt-5",
                on: {
                  click: function ($event) {
                    _vm.isCompanyProfileModalOpen = true
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center emobg-border-top-1 emobg-border-color-ground-light pt-3",
            },
            [
              _c("div", { staticClass: "w-100" }, [
                _c("h2", { staticClass: "mb-3" }, [
                  _vm._v(" Fiscal Information "),
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-lg-3" },
                      [
                        _c(
                          "ContentCellComponent",
                          {
                            staticClass: "mb-3",
                            attrs: { label: "Company legal name" },
                          },
                          [
                            _c("p", { staticClass: "text-break" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "fiscalName") ||
                                      _vm.FALLBACK_MESSAGE.dash
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-lg-3" },
                      [
                        _c(
                          "ContentCellComponent",
                          {
                            staticClass: "mb-4",
                            attrs: { label: "VAT number" },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "fiscalNumber") ||
                                      _vm.FALLBACK_MESSAGE.dash
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.get(_vm.company, "hasItalianOperator")
                      ? [
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-lg-3" },
                            [
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: { label: "Fiscal code" },
                                },
                                [
                                  _c("p", { staticClass: "text-break" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.get(_vm.company, "fiscalCode") ||
                                            _vm.FALLBACK_MESSAGE.dash
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-lg-3" },
                            [
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-3",
                                  attrs: {
                                    label: "Company certificate of registraton",
                                  },
                                },
                                [_c("CompanyCORLink")],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.isFrenchOperator && _vm.hasSiretNumber
                      ? [
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-lg-3" },
                            [
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: { label: "SIRET number" },
                                },
                                [
                                  _c("p", { staticClass: "text-break" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.get(_vm.company, "siretNumber") ||
                                            _vm.FALLBACK_MESSAGE.dash
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c("EditButton", {
                on: {
                  click: function ($event) {
                    _vm.isFiscalInformationModalOpen = true
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-start emobg-border-top-1 emobg-border-color-ground-light pt-3",
            },
            [
              _c("div", { staticClass: "w-100" }, [
                _c("h2", { staticClass: "mb-3" }, [
                  _vm._v(" General Settings "),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: { label: "Premium Company" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "ui-badge",
                                {
                                  attrs: {
                                    color: _vm.get(_vm.company, "isPremium")
                                      ? _vm.COLORS.success
                                      : _vm.GRAYSCALE.ground,
                                    "data-test-id": "company_premium-badge",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(_vm.company, "isPremium")
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: { label: "Payment mode" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "ui-badge",
                                {
                                  attrs: {
                                    color: _vm.GRAYSCALE.ground,
                                    "data-test-id": "payment_mode-badge",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.titleCase(
                                          _vm.get(_vm.company, "paymentMode") ||
                                            _vm.FALLBACK_MESSAGE.dash
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: { label: "Payment method from" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.titleCase(
                                  _vm.get(_vm.company, "paymentMethodFrom") ||
                                    _vm.FALLBACK_MESSAGE.dash
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: { label: "Has dedicated fleet" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "ui-badge",
                                {
                                  attrs: {
                                    color: _vm.get(
                                      _vm.company,
                                      "hasDedicatedFleet"
                                    )
                                      ? _vm.COLORS.success
                                      : _vm.GRAYSCALE.ground,
                                    "data-test-id": "has_dedicated_fleet-badge",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(
                                          _vm.company,
                                          "hasDedicatedFleet"
                                        )
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: { label: "Owns user data" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "ui-badge",
                                {
                                  attrs: {
                                    color: _vm.get(_vm.company, "ownsUserData")
                                      ? _vm.COLORS.success
                                      : _vm.GRAYSCALE.ground,
                                    "data-test-id": "has_dedicated_fleet-badge",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(_vm.company, "ownsUserData")
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("EditButton", {
                staticClass: "mt-5",
                on: {
                  click: function ($event) {
                    _vm.isGeneralSettingsModalOpen = true
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.isCompanyProfileModalOpen
        ? _c("CompanyProfileModal", {
            attrs: {
              "on-success": () =>
                _vm.getCompanyDetails({ companyUuid: _vm.company.uuid }),
            },
            on: {
              closeModal: function ($event) {
                _vm.isCompanyProfileModalOpen = false
              },
            },
          })
        : _vm._e(),
      _vm.isFiscalInformationModalOpen
        ? _c("CompanyFiscalInformationModal", {
            attrs: {
              "on-success": () =>
                _vm.getCompanyDetails({ companyUuid: _vm.company.uuid }),
            },
            on: {
              closeModal: function ($event) {
                _vm.isFiscalInformationModalOpen = false
              },
            },
          })
        : _vm._e(),
      _vm.isGeneralSettingsModalOpen
        ? _c("CompanyGeneralSettingsModal", {
            attrs: {
              "on-success": () =>
                _vm.getCompanyDetails({ companyUuid: _vm.company.uuid }),
            },
            on: {
              closeModal: function ($event) {
                _vm.isGeneralSettingsModalOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }