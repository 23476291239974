<script>
import clone from 'lodash/clone';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import { COMPANY_SIZES } from '@domains/CRM/Companies/const/company.const';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { GOOGLE_ADDRESS_VALIDATIONS, PHONE_VALIDATIONS } from '@/utils';
import { Cancel as CancelButtonAttrs } from '@/constants/modalButtons.const';
import {
  ContentCellComponent,
  GenericModalComponent,
  GoogleLocationSelect,
  PhoneInputComponent,
  StoreNotificationComponent,
} from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

export default {
  name: 'CompanyProfileModal',
  components: {
    ContentCellComponent,
    GenericModalComponent,
    GoogleLocationSelect,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiValidationWrapper,
    PhoneInputComponent,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      inputs: {
        commercialName: null,
        email: null,
        phoneNumber: null,
        addressStreet: null,
        addressStreet2: null,
        localeCode: null,
        size: null,
      },
      isFormValid: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      companyProfileFormStatus: state => state[COMPANY_SCOPES.companyProfileForm].STATUS,
    }),
    modalButtons() {
      return [
        {
          ...CancelButtonAttrs,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            loading: this.companyProfileFormStatus.LOADING,
            disabled: !this.isFormValid,
          },
          text: 'Save',
          listeners: {
            click: () => this.saveCompanyProfile(),
          },
        },
      ];
    },
  },
  created() {
    this.localesIndex = ALGOLIA_INDEXES.locales;
    this.PHONE_VALIDATIONS = PHONE_VALIDATIONS;
    this.GOOGLE_ADDRESS_VALIDATIONS = GOOGLE_ADDRESS_VALIDATIONS;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.COMPANY_SCOPES = COMPANY_SCOPES;
    this.COMPANY_SIZES = map(COMPANY_SIZES, company => ({
      value: company,
      label: company,
    }));

    this.inputs.commercialName = get(this.company, 'commercialName');
    this.inputs.email = get(this.company, 'email');
    this.inputs.phoneNumber = get(this.company, 'phoneNumber');
    this.inputs.addressStreet = get(this.company, 'addressStreet');
    this.inputs.addressStreet2 = get(this.company, 'addressStreet2');
    this.inputs.localeCode = get(this.company, 'language.localeCode');
    this.inputs.size = get(this.company, 'size');
    this.originalInputs = clone(this.inputs);
  },
  methods: {
    get,
    isEqual,
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'putCompanyProfile',
    ]),
    async saveCompanyProfile() {
      await this.putCompanyProfile({
        companyUuid: this.company.uuid,
        payload: this.inputs,
      });

      if (!this.companyProfileFormStatus.ERROR) {
        this.$emit('closeModal');
        this.onSuccess();
        this.$notify({
          message: 'Company profile edited',
          textAction: null,
        });
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    data-test-id="company_profile-form"
    class="CompanyProfileStatusModal"
    title="Edit company profile"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.company"
        :scope="COMPANY_SCOPES.companyProfileForm"
        action="Edit company profile"
      />
    </template>
    <template #body>
      <MuiValidationWrapper
        :extra-conditions="[!(isEqual(originalInputs, inputs))]"
        @areAllValid="isValid => isFormValid = isValid"
      >
        <div class="mb-4">
          <MuiInputText
            v-model="inputs.commercialName"
            v-validate="{
              isRequired: true,
            }"
            label="Company name*"
            name="commercialName"
            class="w-100"
            data-test-id="commercial_name-select"
          />
        </div>
        <div class="row">
          <div class="col-6 mb-4">
            <MuiInputText
              v-model="inputs.email"
              v-validate="{
                isRequired: true,
                isEmail: true,
              }"
              label="Email*"
              name="email"
              class="w-100"
            />
          </div>
          <div class="col-6 mb-4">
            <PhoneInputComponent
              v-model="inputs.phoneNumber"
              v-validate="{
                isRequired: true,
                isValidPhone: PHONE_VALIDATIONS.isValidPhone,
              }"
              label="Phone number*"
              name="phoneNumber"
              placeholder="Enter phone number"
              data-test-id="phone_number"
            />
          </div>
        </div>
        <div class="mb-4">
          <GoogleLocationSelect
            v-model="inputs.addressStreet"
            v-validate.update:place="{
              isRequired: true,
              isValidAddress: GOOGLE_ADDRESS_VALIDATIONS.hasRequiredComponents,
            }"
            label="Address*"
            name="addressStreet"
            class="w-100"
            data-test-id="address-street"
          />
        </div>
        <div class="mb-4">
          <ui-text-input
            :value="inputs.addressStreet2"
            label="Floor, door, apartment..."
            name="addressStreet2"
            class="w-100"
            data-test-id="address_street2"
            @changevalue="({ detail }) => inputs.addressStreet2 = detail"
          />
        </div>
        <div class="row">
          <div class="col-6 mb-4">
            <ContentCellComponent label="Operator">
              {{ get(company, 'csOperator.name') }}
            </ContentCellComponent>
          </div>
          <div class="col-6 mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.localeCode"
              :title="locale => `${locale.code} ${locale.name}`"
              :index="localesIndex"
              filters="active: true"
              label="Language"
              path-value="code"
              class="w-100"
              placeholder="Select"
              name="localeCode"
              data-test-id="locale-select"
            />
          </div>
        </div>
        <ui-select
          :value="inputs.size"
          :options.prop="COMPANY_SIZES"
          class="w-100"
          label="Company size"
          placeholder="Select"
          name="companySize"
          data-test-id="company_size-select"
          @selectoption="({ detail }) => inputs.size = detail"
        />
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
