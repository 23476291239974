<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMPANY_SCOPES } from '../../store/CompanyModule';

export default {
  name: 'CompanyCORLink',
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      certificateOfRegistrationURL: state => state.certificateOfRegistrationURL.data,
      certificateOfRegistrationURLStatus: state => state.certificateOfRegistrationURL.STATUS,
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'getDownloadCOR',
    ]),
    async downloadCOR() {
      await this.getDownloadCOR({ companyUuid: get(this.company, 'uuid') });
      if (!isEmpty(this.certificateOfRegistrationURL)) {
        window.open(get(this.certificateOfRegistrationURL, 'url'), '_blank');
      }
    },
  },
};
</script>

<template>
  <span v-if="!company.hasCertificateOfRegistration">
    {{ FALLBACK_MESSAGE.dash }}
  </span>
  <ui-loader
    v-else-if="certificateOfRegistrationURLStatus.LOADING"
    class="CompanyCORLink__loader"
  />
  <a
    v-else
    href="#"
    class="CompanyCORLink d-flex emobg-link-primary emobg-body-2"
    @click.prevent="downloadCOR"
  >
    Open
    <ui-icon
      :icon="ICONS.externalUrl"
      :size="ICONS_SIZES.small"
      class="ml-1"
    />
  </a>
</template>

<style lang="scss">
.CompanyCORLink__loader {
  .Ui-Loader {
    display: inline-flex;
    margin: 0;

    &__loader {
      width: 18px;
      height: 18px;
      border-width: 2px;
    }
  }
}
</style>
