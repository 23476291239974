var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CompanyStatusModal",
        attrs: {
          header: { isClosable: true },
          size: _vm.SIZES.small,
          buttons: _vm.modalButtons,
          title: "Change company status",
          "data-test-id": "company_status-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.company,
                    scope: _vm.COMPANY_SCOPES.statusForm,
                    action: "Change company status",
                    "is-editing": "",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("MuiAlgoliaSelect", {
                  staticClass: "w-100",
                  attrs: {
                    index: _vm.ALGOLIA_INDEXES.status,
                    title: (status) => status.description,
                    filters: _vm.statusFilters,
                    label: "Company status*",
                    name: "statusId",
                    "no-cache": "",
                    "path-value": "id",
                    placeholder: "Select a status",
                    "data-test-id": "status-select",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "selected",
                      fn: function ({ item }) {
                        return [
                          item
                            ? _c("ui-badge", {
                                attrs: {
                                  text: item.label,
                                  color:
                                    _vm.COMPANY_STATUS_COLORS[
                                      item.algoliaItem.name
                                    ],
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item",
                      fn: function ({ item }) {
                        return [
                          _c("SelectItemBadge", {
                            attrs: {
                              item: item,
                              "map-colors": _vm.COMPANY_STATUS_COLORS,
                              "path-value": "algoliaItem.name",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.inputs.statusId,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "statusId", $$v)
                    },
                    expression: "inputs.statusId",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }