import { render, staticRenderFns } from "./CompanyCORLink.vue?vue&type=template&id=91d17488&"
import script from "./CompanyCORLink.vue?vue&type=script&lang=js&"
export * from "./CompanyCORLink.vue?vue&type=script&lang=js&"
import style0 from "./CompanyCORLink.vue?vue&type=style&index=0&id=91d17488&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('91d17488')) {
      api.createRecord('91d17488', component.options)
    } else {
      api.reload('91d17488', component.options)
    }
    module.hot.accept("./CompanyCORLink.vue?vue&type=template&id=91d17488&", function () {
      api.rerender('91d17488', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/CRM/Companies/Detail/components/CompanyCORLink.vue"
export default component.exports