var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CompanyProfileStatusModal",
        attrs: {
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          "data-test-id": "company_profile-form",
          title: "Edit company profile",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.company,
                    scope: _vm.COMPANY_SCOPES.companyProfileForm,
                    action: "Edit company profile",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  {
                    attrs: {
                      "extra-conditions": [
                        !_vm.isEqual(_vm.originalInputs, _vm.inputs),
                      ],
                    },
                    on: {
                      areAllValid: (isValid) => (_vm.isFormValid = isValid),
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("MuiInputText", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n            isRequired: true,\n          }",
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            label: "Company name*",
                            name: "commercialName",
                            "data-test-id": "commercial_name-select",
                          },
                          model: {
                            value: _vm.inputs.commercialName,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "commercialName", $$v)
                            },
                            expression: "inputs.commercialName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  isEmail: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              isEmail: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: { label: "Email*", name: "email" },
                            model: {
                              value: _vm.inputs.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "email", $$v)
                              },
                              expression: "inputs.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("PhoneInputComponent", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  isValidPhone:
                                    _vm.PHONE_VALIDATIONS.isValidPhone,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              isValidPhone: PHONE_VALIDATIONS.isValidPhone,\n            }",
                              },
                            ],
                            attrs: {
                              label: "Phone number*",
                              name: "phoneNumber",
                              placeholder: "Enter phone number",
                              "data-test-id": "phone_number",
                            },
                            model: {
                              value: _vm.inputs.phoneNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "phoneNumber", $$v)
                              },
                              expression: "inputs.phoneNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("GoogleLocationSelect", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate.update:place",
                              value: {
                                isRequired: true,
                                isValidAddress:
                                  _vm.GOOGLE_ADDRESS_VALIDATIONS
                                    .hasRequiredComponents,
                              },
                              expression:
                                "{\n            isRequired: true,\n            isValidAddress: GOOGLE_ADDRESS_VALIDATIONS.hasRequiredComponents,\n          }",
                              modifiers: { "update:place": true },
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            label: "Address*",
                            name: "addressStreet",
                            "data-test-id": "address-street",
                          },
                          model: {
                            value: _vm.inputs.addressStreet,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "addressStreet", $$v)
                            },
                            expression: "inputs.addressStreet",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("ui-text-input", {
                          staticClass: "w-100",
                          attrs: {
                            value: _vm.inputs.addressStreet2,
                            label: "Floor, door, apartment...",
                            name: "addressStreet2",
                            "data-test-id": "address_street2",
                          },
                          on: {
                            changevalue: ({ detail }) =>
                              (_vm.inputs.addressStreet2 = detail),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c(
                            "ContentCellComponent",
                            { attrs: { label: "Operator" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "csOperator.name")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiAlgoliaSelect", {
                            staticClass: "w-100",
                            attrs: {
                              title: (locale) =>
                                `${locale.code} ${locale.name}`,
                              index: _vm.localesIndex,
                              filters: "active: true",
                              label: "Language",
                              "path-value": "code",
                              placeholder: "Select",
                              name: "localeCode",
                              "data-test-id": "locale-select",
                            },
                            model: {
                              value: _vm.inputs.localeCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "localeCode", $$v)
                              },
                              expression: "inputs.localeCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("ui-select", {
                      staticClass: "w-100",
                      attrs: {
                        value: _vm.inputs.size,
                        label: "Company size",
                        placeholder: "Select",
                        name: "companySize",
                        "data-test-id": "company_size-select",
                      },
                      domProps: { options: _vm.COMPANY_SIZES },
                      on: {
                        selectoption: ({ detail }) =>
                          (_vm.inputs.size = detail),
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }