import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crmRoutes from '../../../router/CRMRouterMap';

export const COMPANY_TABS_LABEL = {
  account: 'Account',
  invoicing: 'Invoicing',
  fleet: 'Fleet',
  tariffs: 'Tariffs',
  bookings: 'Bookings',
  employees: 'Employees',
  settings: 'Settings',
  comments: 'Comments',
  employeePolicy: 'Employee policy',
  corporateContract: 'Corporate contract',
  corporateInvoicing: 'Corporate invoicing',
  fleetSegments: 'Fleet segments',
  circles: 'Circles',
};

export const CORPORATE_TABS = [
  COMPANY_TABS_LABEL.corporateContract,
  COMPANY_TABS_LABEL.corporateInvoicing,
];

export const COMPANY_TABS = [
  {
    label: {
      title: COMPANY_TABS_LABEL.account,
    },
    url: crmRoutes.companies.detail.account,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.invoicing,
    },
    url: crmRoutes.companies.detail.invoicing.index,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.fleet,
    },
    url: crmRoutes.companies.detail.fleet,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.tariffs,
    },
    url: crmRoutes.companies.detail.tariffs,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.bookings,
    },
    url: crmRoutes.companies.detail.bookings,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.employees,
    },
    url: crmRoutes.companies.detail.employees,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.settings,
    },
    url: crmRoutes.companies.detail.settings.index,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.comments,
    },
    url: crmRoutes.companies.detail.comments,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.employeePolicy,
    },
    url: crmRoutes.companies.detail.employeePolicy,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.corporateContract,
    },
    url: crmRoutes.companies.detail.corporateContract.index,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.corporateInvoicing,
    },
    url: crmRoutes.companies.detail.corporateInvoicing.index,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.fleetSegments,
    },
    url: crmRoutes.companies.detail.fleetSegments.index,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies, CRM_PERMISSIONS.viewFleetSegments],
  },
  {
    label: {
      title: COMPANY_TABS_LABEL.circles,
    },
    url: crmRoutes.companies.detail.circles.index,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
];
