<script>
import clone from 'lodash/clone';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import { mapActions, mapState } from 'vuex';
import { sentenceCase, titleCase } from '@emobg/web-utils';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { Cancel as CancelButtonAttrs } from '@/constants/modalButtons.const';
import {
  ContentCellComponent,
  GenericModalComponent,
  SelectItemBadge,
  StoreNotificationComponent,
} from '@/components';
import { COMPANY_PAYMENT_MODES } from '../../../const/company.const';

export default {
  name: 'CompanyGeneralSettingsModal',
  components: {
    ContentCellComponent,
    GenericModalComponent,
    SelectItemBadge,
    StoreNotificationComponent,
  },
  props: {
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      inputs: {
        isPremium: false,
        paymentMode: null,
        hasDedicatedFleet: null,
        ownsUserData: false,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      generalSettingsFormStatus: state => state[COMPANY_SCOPES.generalSettingsForm].STATUS,
    }),
    modalButtons() {
      return [
        {
          ...CancelButtonAttrs,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            loading: this.generalSettingsFormStatus.LOADING,
            disabled: isEqual(this.originalInputs, this.inputs),
          },
          text: 'Save',
          listeners: {
            click: () => this.saveGeneralSettings(),
          },
        },
      ];
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'inputs.hasDedicatedFleet'(newValue) {
      if (!newValue) {
        this.inputs.ownsUserData = false;
      }
    },
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.COMPANY_SCOPES = COMPANY_SCOPES;
    this.PAYMENT_MODES_OPTIONS = map(COMPANY_PAYMENT_MODES, value => ({
      label: sentenceCase(value),
      value,
    }));
    this.BOOLEAN_OPTIONS = [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ];
    this.BOOLEAN_OPTION_COLORS = {
      true: this.COLORS.success,
    };

    this.inputs.paymentMode = this.company.paymentMode;
    this.inputs.ownsUserData = this.company.ownsUserData;
    this.inputs.hasDedicatedFleet = this.company.hasDedicatedFleet;
    this.inputs.isPremium = this.company.isPremium;

    this.originalInputs = clone(this.inputs);
  },
  methods: {
    find,
    titleCase,
    isEqual,
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'putGeneralSettings',
    ]),
    async saveGeneralSettings() {
      await this.putGeneralSettings({
        companyUuid: this.company.uuid,
        payload: this.inputs,
      });

      if (!this.generalSettingsFormStatus.ERROR) {
        this.$emit('closeModal');
        this.onSuccess();
        this.$notify({
          message: 'General settings edited',
          textAction: null,
        });
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    :size="SIZES.small"
    data-test-id="general_settings-form"
    class="CompanyGeneralSettingsModal"
    title="Edit general settings"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.company"
        :scope="COMPANY_SCOPES.generalSettingsForm"
        action="Edit general settings"
      />
    </template>
    <template #body>
      <div class="mb-4">
        <ui-select
          :value.prop="inputs.isPremium"
          label="Premium company*"
          class="w-100"
        >
          <div slot="selected-component">
            <ui-badge
              :text="find(BOOLEAN_OPTIONS, { value: inputs.isPremium }).label"
              :color="BOOLEAN_OPTION_COLORS[inputs.isPremium] || GRAYSCALE.ground"
            />
          </div>
          <div slot="content">
            <ui-select-item
              v-for="(option, index) in BOOLEAN_OPTIONS"
              :key="index"
              :labe="option.label"
              :value="option.value"
              :selected="inputs.isPremium === option.value"
              @click="inputs.isPremium = option.value"
            >
              <SelectItemBadge
                :item="option"
                :map-colors="BOOLEAN_OPTION_COLORS"
              />
            </ui-select-item>
          </div>
        </ui-select>
      </div>
      <div class="mb-4">
        <ui-select
          :value.prop="inputs.paymentMode"
          label="Payment mode*"
          class="w-100"
        >
          <div slot="selected-component">
            <ui-badge
              :text="find(PAYMENT_MODES_OPTIONS, { value: inputs.paymentMode }).label"
              :color="GRAYSCALE.ground"
            />
          </div>
          <div slot="content">
            <ui-select-item
              v-for="(option, index) in PAYMENT_MODES_OPTIONS"
              :key="index"
              :labe="option.label"
              :value="option.value"
              :selected="inputs.paymentMode === option.value"
              @click="inputs.paymentMode = option.value"
            >
              <SelectItemBadge :item="option" />
            </ui-select-item>
          </div>
        </ui-select>
      </div>
      <ContentCellComponent
        :value="titleCase(company.paymentMethodFrom)"
        label="Payment method from"
        class="mb-4"
      />
      <div class="mb-4">
        <ui-select
          :value.prop="inputs.hasDedicatedFleet"
          label="Has dedicated fleet*"
          class="w-100"
        >
          <div slot="selected-component">
            <ui-badge
              :text="find(BOOLEAN_OPTIONS, { value: inputs.hasDedicatedFleet }).label"
              :color="BOOLEAN_OPTION_COLORS[inputs.hasDedicatedFleet] || GRAYSCALE.ground"
            />
          </div>
          <div slot="content">
            <ui-select-item
              v-for="(option, index) in BOOLEAN_OPTIONS"
              :key="index"
              :labe="option.label"
              :value="option.value"
              :selected="inputs.hasDedicatedFleet === option.value"
              @click="inputs.hasDedicatedFleet = option.value"
            >
              <SelectItemBadge
                :item="option"
                :map-colors="BOOLEAN_OPTION_COLORS"
              />
            </ui-select-item>
          </div>
        </ui-select>
      </div>
      <div v-if="inputs.hasDedicatedFleet">
        <ui-select
          :value.prop="inputs.ownsUserData"
          label="Owns user data*"
          class="w-100"
        >
          <div slot="selected-component">
            <ui-badge
              :text="find(BOOLEAN_OPTIONS, { value: inputs.ownsUserData }).label"
              :color="BOOLEAN_OPTION_COLORS[inputs.ownsUserData] || GRAYSCALE.ground"
            />
          </div>
          <div slot="content">
            <ui-select-item
              v-for="(option, index) in BOOLEAN_OPTIONS"
              :key="index"
              :labe="option.label"
              :value="option.value"
              :selected="inputs.ownsUserData === option.value"
              @click="inputs.ownsUserData = option.value"
            >
              <SelectItemBadge
                :item="option"
                :map-colors="BOOLEAN_OPTION_COLORS"
              />
            </ui-select-item>
          </div>
        </ui-select>
      </div>
      <ContentCellComponent
        v-else
        value="No"
        label="Owns user data*"
      />
    </template>
  </GenericModalComponent>
</template>
