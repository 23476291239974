<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import get from 'lodash/get';
import size from 'lodash/size';
import intersection from 'lodash/intersection';
import remove from 'lodash/remove';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import { navigationErrorHandler } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import sharedRoutes from '@domains/Shared/router/SharedRouterMap';
import CRMRouterMap from '@domains/CRM/router/CRMRouterMap';
import { PageTabs, PageView } from '@/components';
import { COMPANY_TABS, CORPORATE_TABS } from './const/tabs';
import { COMPANY_SCOPES } from '../store/CompanyModule';
import CompanyDetailsHeader from './components/CompanyDetailsHeader';

export default {
  name: 'CompanyDetailView',
  components: {
    CompanyDetailsHeader,
    PageTabs,
    PageView,
  },
  beforeRouteLeave(_to, _from, next) {
    this.setData({ scope: COMPANY_SCOPES.company, value: null });
    next();
  },
  props: {
    companyUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      areTabsReady: false,
      tabs: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userPermissions: state => get(state, 'user.data.permissions', []),
    }),
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => {
        const company = state[COMPANY_SCOPES.company].data;
        return isArray(company) ? null : company;
      },
      companyStatus: state => state[COMPANY_SCOPES.company].STATUS,
    }),
  },
  watch: {
    async companyUuid() {
      await this.initialLoad();
    },
  },
  async created() {
    await this.initialLoad();
  },
  methods: {
    get,
    size,
    ...mapMutations(DOMAINS_MODEL.crm.company, ['setData']),
    ...mapActions(DOMAINS_MODEL.crm.company, ['getCompanyDetails']),
    updateTabsVisibility() {
      this.areTabsReady = false;
      const TABS_BY_CONFIG = {
        [CRMRouterMap.companies.detail.fleet]: get(this.company, 'hasDedicatedFleet'),
        [CRMRouterMap.companies.detail.fleetSegments.index]: get(this.company, 'allowFleetSegments'),
      };
      const filteredTabs = filter(COMPANY_TABS, item => {
        const isAllowedByPermissions = intersection(
          this.userPermissions,
          item.permissions,
        ).length;
        const isAllowedByConfig = get(TABS_BY_CONFIG, item.url, true);

        return isAllowedByConfig && isAllowedByPermissions;
      });
      this.tabs = get(this.company, 'hasDedicatedFleet') ? filteredTabs : remove(filteredTabs, tab => CORPORATE_TABS.indexOf(tab.label.title) < 0);
      this.areTabsReady = true;
    },
    async initialLoad() {
      this.isLoading = true;
      await this.getCompanyDetails({ companyUuid: this.companyUuid });

      if (!this.company) {
        this.$router.push({ name: sharedRoutes.notFound }).catch(navigationErrorHandler);
      }

      this.updateTabsVisibility();
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div
    :class="[
      'CompanyDetailView flex-fill d-flex flex-column',
      {
        'justify-content-center': isLoading,
      }
    ]"
    data-test-id="company_detail-view"
  >
    <ui-loader
      v-if="isLoading"
      label="Loading company..."
    />
    <div
      v-else
      class="position-relative"
    >
      <template v-if="get($route, 'meta.isEmptyPage')">
        <PageView>
          <RouterView />
        </PageView>
      </template>
      <template v-else>
        <ui-loader
          v-if="companyStatus.LOADING"
          absolute
        />
        <PageView class="emobg-background-color-white">
          <CompanyDetailsHeader />
        </PageView>
        <PageTabs
          v-if="areTabsReady"
          :tabs="tabs"
          has-overview
        />
        <PageView is-tab-content>
          <Transition
            name="page"
            mode="out-in"
          >
            <RouterView />
          </Transition>
        </PageView>
      </template>
    </div>
  </div>
</template>
