<script>
import get from 'lodash/get';
import has from 'lodash/has';
import { mapActions, mapGetters, mapState } from 'vuex';
import { titleCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { ContentCellComponent, EditButton } from '@/components';
import CompanyProfileModal from './components/CompanyProfileModal';
import CompanyGeneralSettingsModal from './components/CompanyGeneralSettingsModal';
import CompanyCORLink from '../components/CompanyCORLink';
import CompanyFiscalInformationModal from './components/CompanyFiscalInformationModal';

export default {
  name: 'CompanyAccountView',
  components: {
    CompanyCORLink,
    CompanyFiscalInformationModal,
    CompanyGeneralSettingsModal,
    CompanyProfileModal,
    ContentCellComponent,
    EditButton,
  },
  data() {
    return {
      isCompanyProfileModalOpen: false,
      isFiscalInformationModalOpen: false,
      isGeneralSettingsModalOpen: false,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['isFrenchOperator']),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      currentCountryCode: state => get(state, 'operators.countryCode'),
    }),
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state.company.data,
      certificateOfRegistrationURL: state => state.certificateOfRegistrationURL.data,
    }),

    hasSiretNumber() {
      const company = get(this, 'company');
      return has(company, 'siretNumber');
    },
  },
  methods: {
    get,
    titleCase,
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'getCompanyDetails',
    ]),
  },
};
</script>

<template>
  <div
    class="CompanyAccountView"
    data-test-id="company_account-view"
  >
    <h1 class="mb-2">
      Account
    </h1>
    <ui-card
      class="AccountSettings w-100"
      data-test-id="company_account-view"
    >
      <div class="d-flex justify-content-between align-items-start">
        <div class="w-100">
          <h2 class="mb-3">
            Company Profile
          </h2>
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Company name"
                class="mb-3"
              >
                <p class="text-break">
                  {{ get(company, 'commercialName') || FALLBACK_MESSAGE.dash }}
                </p>
              </ContentCellComponent>
              <ContentCellComponent
                label="Email"
                class="mb-3"
              >
                <p class="text-break">
                  {{ get(company, 'email') || FALLBACK_MESSAGE.dash }}
                </p>
              </ContentCellComponent>
              <ContentCellComponent
                label="Phone"
                class="mb-3"
              >
                <p class="text-break">
                  {{ get(company, 'phoneNumber') || FALLBACK_MESSAGE.dash }}
                </p>
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Address"
                class="mb-4"
              >
                <p
                  class="mb-1"
                  data-test-id="address_city"
                >
                  {{ get(company, 'addressCity') || FALLBACK_MESSAGE.dash }}, {{ get(company, 'addressCountry') || FALLBACK_MESSAGE.dash }}
                </p>
                <p
                  class="emobg-color-ink-light mb-1"
                  data-test-id="address_street"
                >
                  {{ get(company, 'addressStreet') || FALLBACK_MESSAGE.dash }}
                  <br>
                  {{ get(company, 'addressPostalCode') || FALLBACK_MESSAGE.dash }}
                </p>
              </ContentCellComponent>
              <ContentCellComponent
                label="Floor, door, apartment..."
                class="mb-3"
              >
                <p class="text-break">
                  {{ get(company, 'addressStreet2') || FALLBACK_MESSAGE.dash }}
                </p>
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Operator"
                class="mb-4"
              >
                <p class="text-break">
                  {{ get(company, 'csOperator.name') || FALLBACK_MESSAGE.dash }}
                </p>
              </ContentCellComponent>
              <ContentCellComponent
                label="Language"
                class="mb-3"
              >
                <p class="text-break">
                  {{ get(company, 'language.localeCode') || FALLBACK_MESSAGE.dash }} - {{ get(company, 'language.name') || FALLBACK_MESSAGE.dash }}
                </p>
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Company size"
                class="mb-3"
              >
                <p class="text-break">
                  {{ get(company, 'size') || FALLBACK_MESSAGE.dash }}
                </p>
              </ContentCellComponent>
            </div>
          </div>
        </div>
        <EditButton
          class="mt-5"
          @click="isCompanyProfileModalOpen = true"
        />
      </div>
      <div class="d-flex justify-content-between align-items-center emobg-border-top-1 emobg-border-color-ground-light pt-3">
        <div class="w-100">
          <h2 class="mb-3">
            Fiscal Information
          </h2>
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Company legal name"
                class="mb-3"
              >
                <p class="text-break">
                  {{ get(company, 'fiscalName') || FALLBACK_MESSAGE.dash }}
                </p>
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="VAT number"
                class="mb-4"
              >
                <p>
                  {{ get(company, 'fiscalNumber') || FALLBACK_MESSAGE.dash }}
                </p>
              </ContentCellComponent>
            </div>
            <template v-if="get(company, 'hasItalianOperator')">
              <div class="col-md-6 col-lg-3">
                <ContentCellComponent
                  label="Fiscal code"
                  class="mb-4"
                >
                  <p class="text-break">
                    {{ get(company, 'fiscalCode') || FALLBACK_MESSAGE.dash }}
                  </p>
                </ContentCellComponent>
              </div>
              <div class="col-md-6 col-lg-3">
                <ContentCellComponent
                  label="Company certificate of registraton"
                  class="mb-3"
                >
                  <CompanyCORLink />
                </ContentCellComponent>
              </div>
            </template>
            <template v-if="isFrenchOperator && hasSiretNumber">
              <div class="col-md-6 col-lg-3">
                <ContentCellComponent
                  label="SIRET number"
                  class="mb-4"
                >
                  <p class="text-break">
                    {{ get(company, 'siretNumber') || FALLBACK_MESSAGE.dash }}
                  </p>
                </ContentCellComponent>
              </div>
            </template>
          </div>
        </div>
        <EditButton @click="isFiscalInformationModalOpen = true" />
      </div>
      <div class="d-flex justify-content-between align-items-start emobg-border-top-1 emobg-border-color-ground-light pt-3">
        <div class="w-100">
          <h2 class="mb-3">
            General Settings
          </h2>
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Premium Company"
                class="mb-4"
              >
                <div class="d-flex align-items-center">
                  <ui-badge
                    :color="get(company, 'isPremium') ? COLORS.success : GRAYSCALE.ground"
                    data-test-id="company_premium-badge"
                  >
                    {{ get(company, 'isPremium') ? 'Yes' : 'No' }}
                  </ui-badge>
                </div>
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Payment mode"
                class="mb-4"
              >
                <div class="d-flex align-items-center">
                  <ui-badge
                    :color="GRAYSCALE.ground"
                    data-test-id="payment_mode-badge"
                  >
                    {{ titleCase(get(company, 'paymentMode') || FALLBACK_MESSAGE.dash) }}
                  </ui-badge>
                </div>
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Payment method from"
                class="mb-4"
              >
                {{ titleCase(get(company, 'paymentMethodFrom') || FALLBACK_MESSAGE.dash) }}
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Has dedicated fleet"
                class="mb-4"
              >
                <div class="d-flex align-items-center">
                  <ui-badge
                    :color="get(company, 'hasDedicatedFleet') ? COLORS.success : GRAYSCALE.ground"
                    data-test-id="has_dedicated_fleet-badge"
                  >
                    {{ get(company, 'hasDedicatedFleet') ? 'Yes' : 'No' }}
                  </ui-badge>
                </div>
              </ContentCellComponent>
              <ContentCellComponent
                label="Owns user data"
                class="mb-4"
              >
                <div class="d-flex align-items-center">
                  <ui-badge
                    :color="get(company, 'ownsUserData') ? COLORS.success : GRAYSCALE.ground"
                    data-test-id="has_dedicated_fleet-badge"
                  >
                    {{ get(company, 'ownsUserData') ? 'Yes' : 'No' }}
                  </ui-badge>
                </div>
              </ContentCellComponent>
            </div>
          </div>
        </div>
        <EditButton
          class="mt-5"
          @click="isGeneralSettingsModalOpen = true"
        />
      </div>
    </ui-card>
    <CompanyProfileModal
      v-if="isCompanyProfileModalOpen"
      :on-success="() => getCompanyDetails({ companyUuid: company.uuid })"
      @closeModal="isCompanyProfileModalOpen = false"
    />
    <CompanyFiscalInformationModal
      v-if="isFiscalInformationModalOpen"
      :on-success="() => getCompanyDetails({ companyUuid: company.uuid })"
      @closeModal="isFiscalInformationModalOpen = false"
    />
    <CompanyGeneralSettingsModal
      v-if="isGeneralSettingsModalOpen"
      :on-success="() => getCompanyDetails({ companyUuid: company.uuid })"
      @closeModal="isGeneralSettingsModalOpen = false"
    />
  </div>
</template>
