var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "CompanyDetailView flex-fill d-flex flex-column",
        {
          "justify-content-center": _vm.isLoading,
        },
      ],
      attrs: { "data-test-id": "company_detail-view" },
    },
    [
      _vm.isLoading
        ? _c("ui-loader", { attrs: { label: "Loading company..." } })
        : _c(
            "div",
            { staticClass: "position-relative" },
            [
              _vm.get(_vm.$route, "meta.isEmptyPage")
                ? [_c("PageView", [_c("RouterView")], 1)]
                : [
                    _vm.companyStatus.LOADING
                      ? _c("ui-loader", { attrs: { absolute: "" } })
                      : _vm._e(),
                    _c(
                      "PageView",
                      { staticClass: "emobg-background-color-white" },
                      [_c("CompanyDetailsHeader")],
                      1
                    ),
                    _vm.areTabsReady
                      ? _c("PageTabs", {
                          attrs: { tabs: _vm.tabs, "has-overview": "" },
                        })
                      : _vm._e(),
                    _c(
                      "PageView",
                      { attrs: { "is-tab-content": "" } },
                      [
                        _c(
                          "Transition",
                          { attrs: { name: "page", mode: "out-in" } },
                          [_c("RouterView")],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }