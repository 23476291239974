<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import {
  CancelButton,
  DeleteButton,
  GenericModalComponent,
} from '@/components';

export default {
  name: 'DeleteEmployeePolicyModal',
  components: {
    CancelButton,
    DeleteButton,
    GenericModalComponent,
  },
  props: {
    employeePolicyId: {
      type: Number,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      deleteEmployeePolicyStatus: state => state[COMPANY_SCOPES.deleteEmployeePolicy].STATUS,
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.company, ['deleteEmployeePolicy']),
    async removeEmployeePolicy() {
      await this.deleteEmployeePolicy({
        companyId: this.company.id,
        statementId: this.employeePolicyId,
      });
      this.$emit('closeModal');
      if (!this.deleteEmployeePolicyStatus.ERROR) {
        this.$notify({
          message: 'Policy deleted',
          textAction: '',
        });
        this.onSuccess();
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{
      title: 'Delete this employee policy?',
      isClosable: true
    }"
    :size="SIZES.small"
    class="DeleteEmployeePolicyModal"
    v-on="$listeners"
  >
    <template slot="body">
      Once deleted you cannot revert the changes.
    </template>
    <template slot="footer">
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="deleteEmployeePolicyStatus.LOADING"
        @click="removeEmployeePolicy"
      />
    </template>
  </GenericModalComponent>
</template>
