<script>
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiCard } from '@emobg/vue-base';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { MarkdownComponent } from '@/components';
import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';
import { COMPANY_SCOPES } from '../../store/CompanyModule';
import EmployeePolicyFormModal from './components/EmployeePolicyFormModal';
import DeleteEmployeePolicyModal from './components/DeleteEmployeePolicyModal';

export default {
  name: 'CompanyEmployeePolicyView',
  components: {
    EmptyStateComponent,
    EmployeePolicyFormModal,
    DeleteEmployeePolicyModal,
    MarkdownComponent,
    MuiCard,
  },
  data() {
    return {
      isModalVisible: false,
      isDeleteModalVisible: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      employeePolicy: state => {
        const { data } = state[COMPANY_SCOPES.employeePolicy];
        return isArray(data) ? undefined : data;
      },
      employeePolicyStatus: state => state[COMPANY_SCOPES.employeePolicy].STATUS,
    }),
    hasEmployeePolicyContent() {
      return !!get(this.employeePolicy, 'content');
    },
  },
  async created() {
    this.employeePolicyActions = [
      {
        label: 'Edit policy',
        action: () => {
          this.isModalVisible = true;
        },
        labelClass: 'emobg-font-weight-semibold',
      },
      {
        label: 'Delete policy',
        action: () => {
          this.isDeleteModalVisible = true;
        },
        labelClass: 'emobg-color-danger emobg-font-weight-semibold',
      },
    ];
    await this.getEmployeePolicy(this.company.id);
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.crm.company, ['getEmployeePolicy']),
    ...mapMutations(DOMAINS_MODEL.crm.company, ['setData']),
    async onSuccessForm(isDelete = false) {
      await this.getEmployeePolicy(this.company.id);

      if (isDelete) {
        // We have to delete it manually as GET endpoint return 404 when no policy present.
        this.setData({
          scope: COMPANY_SCOPES.employeePolicy,
          value: '',
        });
      }
    },
    closeModal() {
      this.isModalVisible = false;
      this.isDeleteModalVisible = false;
    },
  },
};
</script>

<template>
  <div
    class="CompanyEmployeePolicyView"
    data-test-id="company_employee_policy-view"
  >
    <div class="d-flex justify-content-between align-items-center pb-2">
      <h1 class="flex-grow-1">
        Employee policy
      </h1>
      <ui-button
        :disabled="hasEmployeePolicyContent"
        data-test-id="create-button"
        @clickbutton="isModalVisible = true"
      >
        Create policy
      </ui-button>
    </div>

    <MuiCard>
      <template v-if="employeePolicyStatus.LOADING">
        <ui-loader />
      </template>
      <template v-else>
        <div
          v-if="hasEmployeePolicyContent"
          class="d-flex"
        >
          <MarkdownComponent
            :content="{ value: get(employeePolicy, 'content') }"
            language="value"
            class="flex-fill"
          />
          <ui-dropdown>
            <ui-button
              slot="trigger"
              :color="GRAYSCALE.white"
              contrast
              square
            >
              <ui-icon
                :color="GRAYSCALE.inkLight"
                :icon="ICONS.optionsHorizontalFull"
              />
            </ui-button>
            <ui-dropdown-actions
              slot="content"
              :actions.prop="employeePolicyActions"
            />
          </ui-dropdown>
        </div>
        <EmptyStateComponent
          v-else
          title="You haven't added any employee policy yet"
        />
      </template>
    </MuiCard>
    <EmployeePolicyFormModal
      v-if="isModalVisible"
      :policy="employeePolicy"
      :company-id="company.id"
      :on-success="onSuccessForm"
      @closeModal="closeModal"
    />
    <DeleteEmployeePolicyModal
      v-if="isDeleteModalVisible"
      :employee-policy-id="employeePolicy.id"
      :on-success="() => onSuccessForm(true)"
      @closeModal="closeModal"
    />
  </div>
</template>
