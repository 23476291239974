<script>
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import { mapActions, mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  DATE_FORMAT,
  isNilString,
  reformatDateTime,
  titleCase,
} from '@emobg/web-utils';
import {
  ContentCellComponent,
  CopyToClipboard,
  OverviewCollapse,
} from '@/components';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import CompanyStatusModal from './CompanyStatusModal';
import CompanyCORLink from './CompanyCORLink';
import { COMPANY_STATUS_COLORS } from '../../const/company.const';

export default {
  components: {
    CompanyCORLink,
    CompanyStatusModal,
    ContentCellComponent,
    CopyToClipboard,
    OverviewCollapse,
  },
  data() {
    return {
      isStatusModalVisible: false,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['isFrenchOperator']),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      currentCountryCode: state => get(state, 'operators.countryCode'),
    }),
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state.company.data,
      certificateOfRegistrationURL: state => get(state, 'certificateOfRegistrationURL.data'),
      certificateOfRegistrationURLStatus: state => state.certificateOfRegistrationURL.STATUS,
    }),

    hasSiretNumber() {
      const company = get(this, 'company');
      return has(company, 'siretNumber');
    },
  },
  created() {
    this.COMPANY_STATUS_COLORS = COMPANY_STATUS_COLORS;
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    titleCase,
    isNilString,
    reformatDateTime,
    get,
    capitalize,

    ...mapActions(DOMAINS_MODEL.crm.company, [
      'getCompanyDetails',
      'getDownloadCOR',
    ]),
    async downloadCOR() {
      try {
        await this.getDownloadCOR({ companyUuid: get(this.company, 'uuid') });
        if (!isEmpty(this.certificateOfRegistrationURL)) {
          window.open(get(this.certificateOfRegistrationURL, 'url'), '_blank');
        }
      } catch (error) {
        this.$notify({
          message: error,
          textAction: 'OK',
          type: NOTIFICATION_TYPES.error,
        });
      }
    },
  },
};
</script>
<template>
  <div
    class="CompanyDetailsHeader"
    data-test-id="company_details_header"
  >
    <div class="row">
      <div class="col-12">
        <OverviewCollapse id="companyOverview">
          <template #header>
            <div
              class="d-flex flex-wrap align-items-center"
              data-test-id="name"
            >
              <h2 class="emobg-font-weight-semibold mr-3">
                {{ get(company, 'commercialName') || FALLBACK_MESSAGE.dash }}
              </h2>
            </div>
          </template>
          <div class="row pt-4">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Email"
                is-on-header
                class="mb-4"
              >
                <CopyToClipboard
                  v-if="get(company, 'email')"
                  data-test-id="copy_email-button"
                >
                  {{ company.email }}
                </CopyToClipboard>
                <template v-else>
                  {{ FALLBACK_MESSAGE.dash }}
                </template>
              </ContentCellComponent>
              <ContentCellComponent
                label="Phone number"
                is-on-header
                class="mb-4"
              >
                <CopyToClipboard
                  v-if="get(company, 'phoneNumber')"
                  data-test-id="copy_phone_number-action"
                  class="ml-1"
                >
                  {{ get(company, 'phoneNumber') || FALLBACK_MESSAGE.dash }}
                </CopyToClipboard>
                <template v-else>
                  {{ FALLBACK_MESSAGE.dash }}
                </template>
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Address"
                is-on-header
                class="mb-4"
              >
                <p
                  class="mb-1"
                  data-test-id="address_city"
                >
                  {{ get(company, 'addressCity') || FALLBACK_MESSAGE.dash }}, {{ get(company, 'addressCountry') || FALLBACK_MESSAGE.dash }}
                </p>
                <p
                  class="emobg-color-ink-light mb-1"
                  data-test-id="address_street"
                >
                  {{ get(company, 'addressStreet') || FALLBACK_MESSAGE.dash }}, {{ get(company, 'addressStreet2') || FALLBACK_MESSAGE.dash }}
                  <br>
                  {{ get(company, 'addressPostalCode') || FALLBACK_MESSAGE.dash }}
                </p>
              </ContentCellComponent>
              <ContentCellComponent
                label="Fiscal Information"
                is-on-header
                class="mb-4"
              >
                <div class="d-flex">
                  Legal name:
                  <CopyToClipboard
                    v-if="get(company, 'fiscalName')"
                    class="ml-1"
                    data-test-id="copy_fiscal_information_legal_name-action"
                  >
                    {{ get(company, 'fiscalName') }}
                  </CopyToClipboard>
                  <template v-else>
                    {{ FALLBACK_MESSAGE.dash }}
                  </template>
                </div>
                <div class="d-flex mt-1">
                  VAT number:
                  <CopyToClipboard
                    v-if="get(company, 'fiscalNumber')"
                    data-test-id="copy_fiscal_information_vat_number-action"
                    class="ml-1"
                  >
                    {{ get(company, 'fiscalNumber') }}
                  </CopyToClipboard>
                  <template v-else>
                    {{ FALLBACK_MESSAGE.dash }}
                  </template>
                </div>
                <template v-if="company && company.hasItalianOperator">
                  <div class="d-flex mt-1">
                    Fiscal code:
                    <CopyToClipboard
                      v-if="get(company, 'fiscalCode')"
                      data-test-id="copy_fiscal_information_fiscal_code-action"
                      class="ml-1"
                    >
                      {{ get(company, 'fiscalCode') }}
                    </CopyToClipboard>
                    <template v-else>
                      {{ FALLBACK_MESSAGE.dash }}
                    </template>
                  </div>
                  <div class="d-flex flex-wrap align-items-center mt-1">
                    Company certificate of registration:
                    <CompanyCORLink class="ml-1" />
                  </div>
                </template>
                <template v-if="isFrenchOperator && hasSiretNumber">
                  <div class="d-flex mt-1">
                    SIRET number:
                    <CopyToClipboard
                      v-if="get(company, 'siretNumber')"
                      data-test-id="copy_fiscal_information_siret_number-action"
                      class="ml-1"
                    >
                      {{ get(company, 'siretNumber') }}
                    </CopyToClipboard>
                    <template v-else>
                      {{ FALLBACK_MESSAGE.dash }}
                    </template>
                  </div>
                </template>
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Company status"
                is-on-header
                class="mb-4"
              >
                <div class="d-flex align-items-center">
                  <ui-badge
                    :color="COMPANY_STATUS_COLORS[get(company, 'status.name')]"
                    data-test-id="company_status-badge"
                  >
                    {{ capitalize(get(company, 'status.name') || FALLBACK_MESSAGE.dash) }}
                  </ui-badge>
                  <ui-icon
                    :icon="ICONS.edit"
                    :color="GRAYSCALE.inkLight"
                    :size="ICONS_SIZES.small"
                    class="ml-1 cursor-pointer"
                    data-test-id="company_status-action"
                    @click="isStatusModalVisible = true"
                  />
                </div>
              </ContentCellComponent>
              <ContentCellComponent
                label="Premium Company"
                is-on-header
                class="mb-4"
              >
                <div class="d-flex align-items-center">
                  <ui-badge
                    :color="get(company, 'isPremium') ? COLORS.success : GRAYSCALE.ground"
                    data-test-id="company_premium-badge"
                  >
                    {{ get(company, 'isPremium') ? 'Yes' : 'No' }}
                  </ui-badge>
                </div>
              </ContentCellComponent>
              <ContentCellComponent
                label="Payment mode"
                is-on-header
                class="mb-4"
              >
                <div class="d-flex align-items-center">
                  <ui-badge
                    :color="GRAYSCALE.ground"
                    data-test-id="payment_mode-badge"
                  >
                    {{ titleCase(get(company, 'paymentMode') || FALLBACK_MESSAGE.dash) }}
                  </ui-badge>
                </div>
              </ContentCellComponent>
              <ContentCellComponent
                label="Payment method from"
                is-on-header
                class="mb-4"
              >
                {{ titleCase(get(company, 'paymentMethodFrom') || FALLBACK_MESSAGE.dash) }}
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Has dedicated fleet"
                is-on-header
                class="mb-4"
              >
                <div class="d-flex align-items-center">
                  <ui-badge
                    :color="get(company, 'hasDedicatedFleet') ? COLORS.success : GRAYSCALE.ground"
                    data-test-id="has_dedicated_fleet-badge"
                  >
                    {{ get(company, 'hasDedicatedFleet') ? 'Yes' : 'No' }}
                  </ui-badge>
                </div>
              </ContentCellComponent>
              <ContentCellComponent
                label="Personal use of dedicated"
                is-on-header
                class="mb-4"
              >
                <div class="d-flex align-items-center">
                  {{ get(company, 'personalDedicatedUse') ? 'Yes' : 'No' }}
                </div>
              </ContentCellComponent>
              <ContentCellComponent
                label="Personal use of open"
                is-on-header
                class="mb-4"
              >
                <div class="d-flex align-items-center">
                  {{ get(company, 'personalOpenUse') ? 'Yes' : 'No' }}
                </div>
              </ContentCellComponent>
              <ContentCellComponent
                label="Business use of open"
                is-on-header
                class="mb-4"
              >
                <div class="d-flex align-items-center">
                  {{ get(company, 'businessOpenUse') ? 'Yes' : 'No' }}
                </div>
              </ContentCellComponent>
            </div>
          </div>
        </OverviewCollapse>
      </div>
    </div>
    <CompanyStatusModal
      v-if="isStatusModalVisible"
      :on-success="() => getCompanyDetails({ companyUuid: company.uuid })"
      @closeModal="isStatusModalVisible = false"
    />
  </div>
</template>
