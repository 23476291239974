var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CompanyGeneralSettingsModal",
        attrs: {
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          size: _vm.SIZES.small,
          "data-test-id": "general_settings-form",
          title: "Edit general settings",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.company,
                    scope: _vm.COMPANY_SCOPES.generalSettingsForm,
                    action: "Edit general settings",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c(
                      "ui-select",
                      {
                        staticClass: "w-100",
                        attrs: { label: "Premium company*" },
                        domProps: { value: _vm.inputs.isPremium },
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "selected-component" },
                            slot: "selected-component",
                          },
                          [
                            _c("ui-badge", {
                              attrs: {
                                text: _vm.find(_vm.BOOLEAN_OPTIONS, {
                                  value: _vm.inputs.isPremium,
                                }).label,
                                color:
                                  _vm.BOOLEAN_OPTION_COLORS[
                                    _vm.inputs.isPremium
                                  ] || _vm.GRAYSCALE.ground,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          _vm._l(_vm.BOOLEAN_OPTIONS, function (option, index) {
                            return _c(
                              "ui-select-item",
                              {
                                key: index,
                                attrs: {
                                  labe: option.label,
                                  value: option.value,
                                  selected:
                                    _vm.inputs.isPremium === option.value,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.inputs.isPremium = option.value
                                  },
                                },
                              },
                              [
                                _c("SelectItemBadge", {
                                  attrs: {
                                    item: option,
                                    "map-colors": _vm.BOOLEAN_OPTION_COLORS,
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c(
                      "ui-select",
                      {
                        staticClass: "w-100",
                        attrs: { label: "Payment mode*" },
                        domProps: { value: _vm.inputs.paymentMode },
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "selected-component" },
                            slot: "selected-component",
                          },
                          [
                            _c("ui-badge", {
                              attrs: {
                                text: _vm.find(_vm.PAYMENT_MODES_OPTIONS, {
                                  value: _vm.inputs.paymentMode,
                                }).label,
                                color: _vm.GRAYSCALE.ground,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          _vm._l(
                            _vm.PAYMENT_MODES_OPTIONS,
                            function (option, index) {
                              return _c(
                                "ui-select-item",
                                {
                                  key: index,
                                  attrs: {
                                    labe: option.label,
                                    value: option.value,
                                    selected:
                                      _vm.inputs.paymentMode === option.value,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.inputs.paymentMode = option.value
                                    },
                                  },
                                },
                                [
                                  _c("SelectItemBadge", {
                                    attrs: { item: option },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("ContentCellComponent", {
                  staticClass: "mb-4",
                  attrs: {
                    value: _vm.titleCase(_vm.company.paymentMethodFrom),
                    label: "Payment method from",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c(
                      "ui-select",
                      {
                        staticClass: "w-100",
                        attrs: { label: "Has dedicated fleet*" },
                        domProps: { value: _vm.inputs.hasDedicatedFleet },
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "selected-component" },
                            slot: "selected-component",
                          },
                          [
                            _c("ui-badge", {
                              attrs: {
                                text: _vm.find(_vm.BOOLEAN_OPTIONS, {
                                  value: _vm.inputs.hasDedicatedFleet,
                                }).label,
                                color:
                                  _vm.BOOLEAN_OPTION_COLORS[
                                    _vm.inputs.hasDedicatedFleet
                                  ] || _vm.GRAYSCALE.ground,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          _vm._l(_vm.BOOLEAN_OPTIONS, function (option, index) {
                            return _c(
                              "ui-select-item",
                              {
                                key: index,
                                attrs: {
                                  labe: option.label,
                                  value: option.value,
                                  selected:
                                    _vm.inputs.hasDedicatedFleet ===
                                    option.value,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.inputs.hasDedicatedFleet = option.value
                                  },
                                },
                              },
                              [
                                _c("SelectItemBadge", {
                                  attrs: {
                                    item: option,
                                    "map-colors": _vm.BOOLEAN_OPTION_COLORS,
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.inputs.hasDedicatedFleet
                  ? _c(
                      "div",
                      [
                        _c(
                          "ui-select",
                          {
                            staticClass: "w-100",
                            attrs: { label: "Owns user data*" },
                            domProps: { value: _vm.inputs.ownsUserData },
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: { slot: "selected-component" },
                                slot: "selected-component",
                              },
                              [
                                _c("ui-badge", {
                                  attrs: {
                                    text: _vm.find(_vm.BOOLEAN_OPTIONS, {
                                      value: _vm.inputs.ownsUserData,
                                    }).label,
                                    color:
                                      _vm.BOOLEAN_OPTION_COLORS[
                                        _vm.inputs.ownsUserData
                                      ] || _vm.GRAYSCALE.ground,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              _vm._l(
                                _vm.BOOLEAN_OPTIONS,
                                function (option, index) {
                                  return _c(
                                    "ui-select-item",
                                    {
                                      key: index,
                                      attrs: {
                                        labe: option.label,
                                        value: option.value,
                                        selected:
                                          _vm.inputs.ownsUserData ===
                                          option.value,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.inputs.ownsUserData = option.value
                                        },
                                      },
                                    },
                                    [
                                      _c("SelectItemBadge", {
                                        attrs: {
                                          item: option,
                                          "map-colors":
                                            _vm.BOOLEAN_OPTION_COLORS,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c("ContentCellComponent", {
                      attrs: { value: "No", label: "Owns user data*" },
                    }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }