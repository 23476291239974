var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CompanyFiscalInformationModal",
        attrs: {
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          size: _vm.SIZES.small,
          "data-test-id": "fiscal_information-form",
          title: "Edit fiscal information",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.company,
                    scope: _vm.COMPANY_SCOPES.fiscalInformationForm,
                    action: "Edit fiscal information",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "ui-validate",
                  {
                    on: {
                      status: ({ detail }) =>
                        (_vm.isFormValid = detail.isValid),
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("ui-text-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate.input",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n            isRequired: true,\n          }",
                              modifiers: { input: true },
                            },
                          ],
                          attrs: {
                            value: _vm.inputs.fiscalName,
                            label: "Company legal name*",
                          },
                          on: {
                            changevalue: ({ detail }) =>
                              (_vm.inputs.fiscalName = detail),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("ui-text-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate.input",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n            isRequired: true,\n          }",
                              modifiers: { input: true },
                            },
                          ],
                          attrs: {
                            value: _vm.inputs.fiscalNumber,
                            label: "VAT number*",
                          },
                          on: {
                            changevalue: ({ detail }) =>
                              (_vm.inputs.fiscalNumber = detail),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.company.hasItalianOperator
                      ? [
                          _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c("ui-text-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate.input",
                                    value: {
                                      isRequired: true,
                                      isValidFiscalCode:
                                        _vm.FISCAL_CODE_VALIDATIONS
                                          .isValidCompanyFiscalCode,
                                    },
                                    expression:
                                      "{\n              isRequired: true,\n              isValidFiscalCode: FISCAL_CODE_VALIDATIONS.isValidCompanyFiscalCode,\n            }",
                                    modifiers: { input: true },
                                  },
                                ],
                                attrs: {
                                  value: _vm.inputs.fiscalCode,
                                  label: "Fiscal code*",
                                },
                                on: {
                                  changevalue: ({ detail }) =>
                                    (_vm.inputs.fiscalCode = detail),
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "d-block emobg-font-x-small emobg-color-ink pl-1 mt-1",
                                },
                                [
                                  _vm._v(
                                    " Fiscal code is a unique combination of 16 numbers and letters or 11 numbers "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "ContentCellComponent",
                            {
                              attrs: {
                                label: "Company certificate of registration",
                              },
                            },
                            [_c("CompanyCORLink")],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.isFrenchOperator && _vm.hasSiretNumber
                      ? [
                          _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c("ui-text-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate.input",
                                    value: {
                                      isRequired: true,
                                      isPattern: {
                                        pattern:
                                          _vm.FRENCH_COMPANY_VALIDATION_PATTERN
                                            .siretNumber,
                                        message: "Invalid SIRET number format",
                                      },
                                    },
                                    expression:
                                      "{\n              isRequired: true,\n              isPattern: {\n                pattern: FRENCH_COMPANY_VALIDATION_PATTERN.siretNumber,\n                message: 'Invalid SIRET number format',\n              },\n            }",
                                    modifiers: { input: true },
                                  },
                                ],
                                attrs: {
                                  value: _vm.inputs.siretNumber,
                                  label: "SIRET number*",
                                  "data-test-id": "siret_number",
                                },
                                on: {
                                  changevalue: ({ detail }) =>
                                    (_vm.inputs.siretNumber = detail),
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "d-block emobg-font-x-small emobg-color-ink pl-1 mt-1",
                                },
                                [
                                  _vm._v(
                                    " SIRET number is a unique combination of 14 numbers "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }