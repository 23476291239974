var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyDetailsHeader",
      attrs: { "data-test-id": "company_details_header" },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "OverviewCollapse",
              {
                attrs: { id: "companyOverview" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex flex-wrap align-items-center",
                            attrs: { "data-test-id": "name" },
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass: "emobg-font-weight-semibold mr-3",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.get(_vm.company, "commercialName") ||
                                        _vm.FALLBACK_MESSAGE.dash
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("div", { staticClass: "row pt-4" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: { label: "Email", "is-on-header": "" },
                        },
                        [
                          _vm.get(_vm.company, "email")
                            ? _c(
                                "CopyToClipboard",
                                {
                                  attrs: {
                                    "data-test-id": "copy_email-button",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.company.email) + " ")]
                              )
                            : [
                                _vm._v(
                                  " " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "
                                ),
                              ],
                        ],
                        2
                      ),
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: { label: "Phone number", "is-on-header": "" },
                        },
                        [
                          _vm.get(_vm.company, "phoneNumber")
                            ? _c(
                                "CopyToClipboard",
                                {
                                  staticClass: "ml-1",
                                  attrs: {
                                    "data-test-id": "copy_phone_number-action",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(_vm.company, "phoneNumber") ||
                                          _vm.FALLBACK_MESSAGE.dash
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : [
                                _vm._v(
                                  " " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "
                                ),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: { label: "Address", "is-on-header": "" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "mb-1",
                              attrs: { "data-test-id": "address_city" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "addressCity") ||
                                      _vm.FALLBACK_MESSAGE.dash
                                  ) +
                                  ", " +
                                  _vm._s(
                                    _vm.get(_vm.company, "addressCountry") ||
                                      _vm.FALLBACK_MESSAGE.dash
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "emobg-color-ink-light mb-1",
                              attrs: { "data-test-id": "address_street" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "addressStreet") ||
                                      _vm.FALLBACK_MESSAGE.dash
                                  ) +
                                  ", " +
                                  _vm._s(
                                    _vm.get(_vm.company, "addressStreet2") ||
                                      _vm.FALLBACK_MESSAGE.dash
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "addressPostalCode") ||
                                      _vm.FALLBACK_MESSAGE.dash
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: "Fiscal Information",
                            "is-on-header": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _vm._v(" Legal name: "),
                              _vm.get(_vm.company, "fiscalName")
                                ? _c(
                                    "CopyToClipboard",
                                    {
                                      staticClass: "ml-1",
                                      attrs: {
                                        "data-test-id":
                                          "copy_fiscal_information_legal_name-action",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.get(_vm.company, "fiscalName")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.FALLBACK_MESSAGE.dash) +
                                        " "
                                    ),
                                  ],
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex mt-1" },
                            [
                              _vm._v(" VAT number: "),
                              _vm.get(_vm.company, "fiscalNumber")
                                ? _c(
                                    "CopyToClipboard",
                                    {
                                      staticClass: "ml-1",
                                      attrs: {
                                        "data-test-id":
                                          "copy_fiscal_information_vat_number-action",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.get(_vm.company, "fiscalNumber")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.FALLBACK_MESSAGE.dash) +
                                        " "
                                    ),
                                  ],
                            ],
                            2
                          ),
                          _vm.company && _vm.company.hasItalianOperator
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-1" },
                                  [
                                    _vm._v(" Fiscal code: "),
                                    _vm.get(_vm.company, "fiscalCode")
                                      ? _c(
                                          "CopyToClipboard",
                                          {
                                            staticClass: "ml-1",
                                            attrs: {
                                              "data-test-id":
                                                "copy_fiscal_information_fiscal_code-action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.get(
                                                    _vm.company,
                                                    "fiscalCode"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.FALLBACK_MESSAGE.dash
                                              ) +
                                              " "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-wrap align-items-center mt-1",
                                  },
                                  [
                                    _vm._v(
                                      " Company certificate of registration: "
                                    ),
                                    _c("CompanyCORLink", {
                                      staticClass: "ml-1",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm.isFrenchOperator && _vm.hasSiretNumber
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-1" },
                                  [
                                    _vm._v(" SIRET number: "),
                                    _vm.get(_vm.company, "siretNumber")
                                      ? _c(
                                          "CopyToClipboard",
                                          {
                                            staticClass: "ml-1",
                                            attrs: {
                                              "data-test-id":
                                                "copy_fiscal_information_siret_number-action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.get(
                                                    _vm.company,
                                                    "siretNumber"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.FALLBACK_MESSAGE.dash
                                              ) +
                                              " "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: "Company status",
                            "is-on-header": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "ui-badge",
                                {
                                  attrs: {
                                    color:
                                      _vm.COMPANY_STATUS_COLORS[
                                        _vm.get(_vm.company, "status.name")
                                      ],
                                    "data-test-id": "company_status-badge",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.capitalize(
                                          _vm.get(_vm.company, "status.name") ||
                                            _vm.FALLBACK_MESSAGE.dash
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("ui-icon", {
                                staticClass: "ml-1 cursor-pointer",
                                attrs: {
                                  icon: _vm.ICONS.edit,
                                  color: _vm.GRAYSCALE.inkLight,
                                  size: _vm.ICONS_SIZES.small,
                                  "data-test-id": "company_status-action",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.isStatusModalVisible = true
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: "Premium Company",
                            "is-on-header": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "ui-badge",
                                {
                                  attrs: {
                                    color: _vm.get(_vm.company, "isPremium")
                                      ? _vm.COLORS.success
                                      : _vm.GRAYSCALE.ground,
                                    "data-test-id": "company_premium-badge",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(_vm.company, "isPremium")
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: { label: "Payment mode", "is-on-header": "" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "ui-badge",
                                {
                                  attrs: {
                                    color: _vm.GRAYSCALE.ground,
                                    "data-test-id": "payment_mode-badge",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.titleCase(
                                          _vm.get(_vm.company, "paymentMode") ||
                                            _vm.FALLBACK_MESSAGE.dash
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: "Payment method from",
                            "is-on-header": "",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.titleCase(
                                  _vm.get(_vm.company, "paymentMethodFrom") ||
                                    _vm.FALLBACK_MESSAGE.dash
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: "Has dedicated fleet",
                            "is-on-header": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "ui-badge",
                                {
                                  attrs: {
                                    color: _vm.get(
                                      _vm.company,
                                      "hasDedicatedFleet"
                                    )
                                      ? _vm.COLORS.success
                                      : _vm.GRAYSCALE.ground,
                                    "data-test-id": "has_dedicated_fleet-badge",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(
                                          _vm.company,
                                          "hasDedicatedFleet"
                                        )
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: "Personal use of dedicated",
                            "is-on-header": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "personalDedicatedUse")
                                      ? "Yes"
                                      : "No"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: "Personal use of open",
                            "is-on-header": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "personalOpenUse")
                                      ? "Yes"
                                      : "No"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: "Business use of open",
                            "is-on-header": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.company, "businessOpenUse")
                                      ? "Yes"
                                      : "No"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.isStatusModalVisible
        ? _c("CompanyStatusModal", {
            attrs: {
              "on-success": () =>
                _vm.getCompanyDetails({ companyUuid: _vm.company.uuid }),
            },
            on: {
              closeModal: function ($event) {
                _vm.isStatusModalVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }