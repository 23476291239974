<script>
import clone from 'lodash/clone';
import get from 'lodash/get';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Validate } from '@emobg/vue-base';
import { FRENCH_COMPANY_VALIDATION_PATTERN } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import {
  ContentCellComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { Cancel as CancelButtonAttrs } from '@/constants/modalButtons.const';
import { FISCAL_CODE_VALIDATIONS } from '@/utils';
import CompanyCORLink from '../../components/CompanyCORLink';

export default {
  name: 'CompanyFiscalInformationModal',
  components: {
    ContentCellComponent,
    CompanyCORLink,
    GenericModalComponent,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      inputs: {
        fiscalName: '',
        fiscalNumber: '',
        fiscalCode: null,
        siretNumber: null,
      },
      isFormValid: false,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['isFrenchOperator']),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      currentCountryCode: state => get(state, 'operators.countryCode'),
    }),

    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      fiscalInformationFormStatus: state => state[COMPANY_SCOPES.fiscalInformationForm].STATUS,
    }),

    hasSiretNumber() {
      const company = get(this, 'company');
      return has(company, 'siretNumber');
    },
    modalButtons() {
      return [
        {
          ...CancelButtonAttrs,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            loading: this.fiscalInformationFormStatus.LOADING,
            disabled: !this.isFormValid || isEqual(this.originalInputs, this.inputs),
          },
          text: 'Save',
          listeners: {
            click: () => this.saveFiscalInformation(),
          },
        },
      ];
    },
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.COMPANY_SCOPES = COMPANY_SCOPES;
    this.FISCAL_CODE_VALIDATIONS = FISCAL_CODE_VALIDATIONS;
    this.FRENCH_COMPANY_VALIDATION_PATTERN = FRENCH_COMPANY_VALIDATION_PATTERN;

    this.inputs.fiscalName = this.company.fiscalName;
    this.inputs.fiscalNumber = this.company.fiscalNumber;

    if (this.company) {
      if (this.company.hasItalianOperator) {
        this.inputs.fiscalCode = this.company.fiscalCode;
      }

      if (this.isFrenchOperator) {
        this.inputs.siretNumber = this.company.siretNumber;
      }
    }

    this.originalInputs = clone(this.inputs);
  },
  methods: {
    isEqual,
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'putFiscalInformation',
    ]),
    async saveFiscalInformation() {
      await this.putFiscalInformation({
        companyUuid: this.company.uuid,
        payload: this.inputs,
      });

      if (!this.fiscalInformationFormStatus.ERROR) {
        this.$emit('closeModal');
        this.onSuccess();
        this.$notify({
          message: 'Fiscal information edited',
          textAction: null,
        });
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    :size="SIZES.small"
    data-test-id="fiscal_information-form"
    class="CompanyFiscalInformationModal"
    title="Edit fiscal information"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.company"
        :scope="COMPANY_SCOPES.fiscalInformationForm"
        action="Edit fiscal information"
      />
    </template>
    <template #body>
      <ui-validate @status="({ detail }) => isFormValid = detail.isValid">
        <div class="mb-4">
          <ui-text-input
            v-validate.input="{
              isRequired: true,
            }"
            :value="inputs.fiscalName"
            label="Company legal name*"
            @changevalue="({ detail }) => inputs.fiscalName = detail"
          />
        </div>
        <div class="mb-4">
          <ui-text-input
            v-validate.input="{
              isRequired: true,
            }"
            :value="inputs.fiscalNumber"
            label="VAT number*"
            @changevalue="({ detail }) => inputs.fiscalNumber = detail"
          />
        </div>
        <template v-if="company.hasItalianOperator">
          <div class="mb-4">
            <ui-text-input
              v-validate.input="{
                isRequired: true,
                isValidFiscalCode: FISCAL_CODE_VALIDATIONS.isValidCompanyFiscalCode,
              }"
              :value="inputs.fiscalCode"
              label="Fiscal code*"
              @changevalue="({ detail }) => inputs.fiscalCode = detail"
            />
            <label class="d-block emobg-font-x-small emobg-color-ink pl-1 mt-1">
              Fiscal code is a unique combination of 16 numbers and letters or 11 numbers
            </label>
          </div>
          <ContentCellComponent label="Company certificate of registration">
            <CompanyCORLink />
          </ContentCellComponent>
        </template>
        <template v-if="isFrenchOperator && hasSiretNumber">
          <div class="mb-4">
            <ui-text-input
              v-validate.input="{
                isRequired: true,
                isPattern: {
                  pattern: FRENCH_COMPANY_VALIDATION_PATTERN.siretNumber,
                  message: 'Invalid SIRET number format',
                },
              }"
              :value="inputs.siretNumber"
              label="SIRET number*"
              data-test-id="siret_number"
              @changevalue="({ detail }) => inputs.siretNumber = detail"
            />
            <label class="d-block emobg-font-x-small emobg-color-ink pl-1 mt-1">
              SIRET number is a unique combination of 14 numbers
            </label>
          </div>
        </template>
      </ui-validate>
    </template>
  </GenericModalComponent>
</template>
