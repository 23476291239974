<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import { MuiAlgoliaSelect } from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import { crm as crmErrors } from '@domains/CRM/const/errorKeys';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { Cancel as CancelAttrs } from '@/constants/modalButtons.const';
import {
  GenericModalComponent,
  SelectItemBadge,
  StoreNotificationComponent,
} from '@/components';
import { COMPANY_STATUS_COLORS } from '../../const/company.const';

export default {
  name: 'CompanyStatusModal',
  components: {
    GenericModalComponent,
    MuiAlgoliaSelect,
    SelectItemBadge,
    StoreNotificationComponent,
  },
  props: {
    onSuccess: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      inputs: {
        statusId: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      companyStatusSTATUS: state => state[COMPANY_SCOPES.statusForm].STATUS,
    }),
    modalButtons() {
      return [
        {
          ...CancelAttrs,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            disabled: !this.isStatusChanged,
            loading: this.companyStatusSTATUS.LOADING,
          },
          text: 'Save',
          listeners: {
            click: this.saveStatus,
          },
        },
      ];
    },
    isStatusChanged() {
      return get(this.company, 'status.id') !== this.inputs.statusId;
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.COMPANY_SCOPES = COMPANY_SCOPES;
    this.COMPANY_STATUS_COLORS = COMPANY_STATUS_COLORS;
    this.crmErrors = crmErrors;

    this.statusFilters = 'group:companies AND agent_usable:1';

    this.inputs.statusId = get(this.company, 'status.id');
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'putCompanyStatus',
    ]),
    async saveStatus() {
      await this.putCompanyStatus({
        companyUuid: this.company.uuid,
        payload: this.inputs,
      });

      if (!this.companyStatusSTATUS.ERROR) {
        this.onSuccess();
        this.$emit('closeModal');
        this.$notify({
          message: 'Company status edited',
          textAction: '',
        });
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :size="SIZES.small"
    :buttons="modalButtons"
    title="Change company status"
    class="CompanyStatusModal"
    data-test-id="company_status-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.company"
        :scope="COMPANY_SCOPES.statusForm"
        action="Change company status"
        is-editing
      />
    </template>
    <template #body>
      <MuiAlgoliaSelect
        v-model="inputs.statusId"
        :index="ALGOLIA_INDEXES.status"
        :title="status => status.description"
        :filters="statusFilters"
        label="Company status*"
        name="statusId"
        no-cache
        path-value="id"
        placeholder="Select a status"
        class="w-100"
        data-test-id="status-select"
      >
        <template #selected="{ item }">
          <ui-badge
            v-if="item"
            :text="item.label"
            :color="COMPANY_STATUS_COLORS[item.algoliaItem.name]"
          />
        </template>
        <template #item="{ item }">
          <SelectItemBadge
            :item="item"
            :map-colors="COMPANY_STATUS_COLORS"
            path-value="algoliaItem.name"
          />
        </template>
      </MuiAlgoliaSelect>
    </template>
  </GenericModalComponent>
</template>
