var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.company.hasCertificateOfRegistration
    ? _c("span", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")])
    : _vm.certificateOfRegistrationURLStatus.LOADING
    ? _c("ui-loader", { staticClass: "CompanyCORLink__loader" })
    : _c(
        "a",
        {
          staticClass: "CompanyCORLink d-flex emobg-link-primary emobg-body-2",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.downloadCOR.apply(null, arguments)
            },
          },
        },
        [
          _vm._v(" Open "),
          _c("ui-icon", {
            staticClass: "ml-1",
            attrs: { icon: _vm.ICONS.externalUrl, size: _vm.ICONS_SIZES.small },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }