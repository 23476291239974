var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "EmployeePolicyFormModal",
        attrs: {
          header: { isClosable: true },
          title: `${_vm.isEdit ? "Edit" : "Create"} employee policy`,
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.company,
                    scope: _vm.COMPANY_SCOPES.employeePolicyForm,
                    action: _vm.isEdit ? "Save" : "Create",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "ui-alert",
                  {
                    staticClass: "mb-3 d-flex w-100",
                    attrs: {
                      color: _vm.COLORS.warning,
                      icon: _vm.ICONS.alertFull,
                    },
                  },
                  [
                    _vm._v(
                      " Policy is visible to employees in apps and on the website "
                    ),
                  ]
                ),
                _c(
                  "MuiValidationWrapper",
                  {
                    attrs: { "extra-conditions": [!_vm.isPolicyIdentical] },
                    on: {
                      areAllValid: (isValid) => (_vm.isFormValid = isValid),
                    },
                  },
                  [
                    _c("MuiTextarea", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                          },
                          expression:
                            "{\n          isRequired: true,\n        }",
                        },
                      ],
                      attrs: {
                        label: "Employee Policy*",
                        placeholder: "Enter policy",
                        name: "policy",
                        rows: "5",
                      },
                      model: {
                        value: _vm.input.policy,
                        callback: function ($$v) {
                          _vm.$set(_vm.input, "policy", $$v)
                        },
                        expression: "input.policy",
                      },
                    }),
                    _c("MarkdownInformation"),
                    _c("div", { staticClass: "mt-3 d-block w-100" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-9" },
                        [
                          _c("ui-label", {
                            attrs: { label: "Policy preview:" },
                          }),
                          _c("br"),
                          _c("MarkdownComponent", {
                            attrs: {
                              content: {
                                value:
                                  _vm.input.policy || _vm.FALLBACK_MESSAGE.dash,
                              },
                              language: "value",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeModal")
                        },
                      },
                    }),
                    _c(
                      "ui-button",
                      {
                        attrs: {
                          disabled:
                            !_vm.isFormValid || _vm.isEmployeePolicyLoading,
                          loading: _vm.isEmployeePolicyLoading,
                          narrow: "",
                        },
                        on: { clickbutton: _vm.policyRequest },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.isEdit ? "Save" : "Create") + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }