var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyEmployeePolicyView",
      attrs: { "data-test-id": "company_employee_policy-view" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center pb-2",
        },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [
            _vm._v(" Employee policy "),
          ]),
          _c(
            "ui-button",
            {
              attrs: {
                disabled: _vm.hasEmployeePolicyContent,
                "data-test-id": "create-button",
              },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v(" Create policy ")]
          ),
        ],
        1
      ),
      _c(
        "MuiCard",
        [
          _vm.employeePolicyStatus.LOADING
            ? [_c("ui-loader")]
            : [
                _vm.hasEmployeePolicyContent
                  ? _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("MarkdownComponent", {
                          staticClass: "flex-fill",
                          attrs: {
                            content: {
                              value: _vm.get(_vm.employeePolicy, "content"),
                            },
                            language: "value",
                          },
                        }),
                        _c(
                          "ui-dropdown",
                          [
                            _c(
                              "ui-button",
                              {
                                attrs: {
                                  slot: "trigger",
                                  color: _vm.GRAYSCALE.white,
                                  contrast: "",
                                  square: "",
                                },
                                slot: "trigger",
                              },
                              [
                                _c("ui-icon", {
                                  attrs: {
                                    color: _vm.GRAYSCALE.inkLight,
                                    icon: _vm.ICONS.optionsHorizontalFull,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("ui-dropdown-actions", {
                              attrs: { slot: "content" },
                              domProps: { actions: _vm.employeePolicyActions },
                              slot: "content",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("EmptyStateComponent", {
                      attrs: {
                        title: "You haven't added any employee policy yet",
                      },
                    }),
              ],
        ],
        2
      ),
      _vm.isModalVisible
        ? _c("EmployeePolicyFormModal", {
            attrs: {
              policy: _vm.employeePolicy,
              "company-id": _vm.company.id,
              "on-success": _vm.onSuccessForm,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteModalVisible
        ? _c("DeleteEmployeePolicyModal", {
            attrs: {
              "employee-policy-id": _vm.employeePolicy.id,
              "on-success": () => _vm.onSuccessForm(true),
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }