<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import {
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import {
  CancelButton,
  GenericModalComponent,
  MarkdownComponent,
  MarkdownInformation,
  StoreNotificationComponent,
} from '@/components';

export default {
  name: 'EmployeePolicyFormModal',
  components: {
    CancelButton,
    MarkdownComponent,
    MarkdownInformation,
    GenericModalComponent,
    MuiTextarea,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    companyId: {
      type: Number,
      default: null,
    },
    policy: {
      type: Object,
      default: null,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: true,
      input: {
        policy: '',
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      isEmployeePolicyLoading: state => state[COMPANY_SCOPES.employeePolicyForm].STATUS.LOADING,
      isEmployeePolicyError: state => state[COMPANY_SCOPES.employeePolicyForm].STATUS.ERROR,
    }),
    isEdit() {
      return !!this.policy;
    },
    isPolicyIdentical() {
      return get(this.policy, 'content', '') === this.input.policy;
    },
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.COMPANY_SCOPES = COMPANY_SCOPES;
  },
  mounted() {
    this.input.policy = get(this.policy, 'content');
  },
  methods: {
    sentenceCase,
    ...mapActions(DOMAINS_MODEL.crm.company, ['putEmployeePolicy', 'postEmployeePolicy']),
    async policyRequest() {
      const request = this.isEdit ? this.putEmployeePolicy : this.postEmployeePolicy;

      const policyPayload = {
        companyId: this.companyId,
        payload: {
          content: this.input.policy,
        },
      };

      const policyEditPayload = {
        ...policyPayload,
        statementId: get(this.policy, 'id'),
      };

      await request(this.isEdit ? policyEditPayload : policyPayload);

      if (!this.isEmployeePolicyError) {
        const message = this.isEdit
          ? 'Policy edited'
          : 'Policy created';
        this.$emit('closeModal');
        this.$notify({
          message,
          textAction: '',
        });
        this.onSuccess();
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :title="`${isEdit ? 'Edit' : 'Create'} employee policy`"
    class="EmployeePolicyFormModal"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.company"
        :scope="COMPANY_SCOPES.employeePolicyForm"
        :action="isEdit ? 'Save' : 'Create'"
      />
    </template>
    <template #body>
      <ui-alert
        :color="COLORS.warning"
        :icon="ICONS.alertFull"
        class="mb-3 d-flex w-100"
      >
        Policy is visible to employees in apps and on the website
      </ui-alert>
      <MuiValidationWrapper
        :extra-conditions="[!isPolicyIdentical]"
        @areAllValid="isValid => isFormValid = isValid"
      >
        <MuiTextarea
          v-model="input.policy"
          v-validate="{
            isRequired: true,
          }"
          label="Employee Policy*"
          placeholder="Enter policy"
          name="policy"
          rows="5"
        />
        <MarkdownInformation />
        <div class="mt-3 d-block w-100">
          <div class="col-md-9">
            <ui-label label="Policy preview:" />
            <br>
            <MarkdownComponent
              :content="{ value: input.policy || FALLBACK_MESSAGE.dash }"
              language="value"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton @click="$emit('closeModal')" />
        <ui-button
          :disabled="!isFormValid || isEmployeePolicyLoading"
          :loading="isEmployeePolicyLoading"
          narrow
          @clickbutton="policyRequest"
        >
          {{ isEdit ? 'Save' : 'Create' }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
