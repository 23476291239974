import { COLORS, GRAYSCALE } from '@emobg/vue-base';

export const COMPANY_STATUS = {
  activated: 'activated',
  unpaid: 'unpaid',
  deactivated: 'deactivated',
};

export const COMPANY_STATUS_COLORS = {
  [COMPANY_STATUS.activated]: COLORS.success,
  [COMPANY_STATUS.deactivated]: GRAYSCALE.ground,
  [COMPANY_STATUS.unpaid]: COLORS.warning,
};

export const COMPANY_PAYMENT_MODES = Object.freeze({
  payPerUse: 'pay_per_use',
  payMonthly: 'pay_monthly',
});

export const COMPANY_SIZES = Object.freeze([
  'unknown',
  '2-5',
  '6-10',
  '11-20',
  '21-50',
  '51-100',
  '101-200',
  '> 200',
]);
