import { FACES, GRAYSCALE } from '@emobg/vue-base';

// TODO: rename const to more valuable context https://europcarmobility.atlassian.net/browse/CF-433
export const Cancel = {
  attributes: {
    // TODO: https://europcarmobility.atlassian.net/browse/CF-427
    'data-test-id': 'cancel-button',
    color: GRAYSCALE.inkLight,
    face: FACES.text,
  },
  text: 'Cancel',
};
